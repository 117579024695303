import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-leander-training',
  templateUrl: './leander-training.component.html',
  styleUrls: ['./leander-training.component.css']
})
export class LeanderTrainingComponent implements OnInit {


  data: any = [];
  studentLabels: any = [];
  schedule: any = '';
  scheduleclass: any = [];
  studentlevel: any = [];
  allcoaches: any = [];
  servicedata: any = [];
  name: any;
  email: any;
  mobile_no: any;
  residence: any;
  history: any;
  reference: any;
  certificate: any;
  summary: any;
  successMessage: any = '';
  errorMessage: any = '';
  schedules: any = [];

  tabs = [
    { "id": "14", "label": "Leander Beginner Kids" },
    { "id": "15", "label": "Leander Intermediate Kids (Pre-requisite: Evaluation required)" },
    { "id": "16", "label": "Leander Advanced Team" },
    { "id": "17", "label": "Leander Competition Team (2 Classes in Leander and 3 Classes in Roundrock)" },
    { "id": "18", "label": "Leander Beginner Adult" },
    { "id": "19", "label": "Leander Intermediate Adult (Pre-requisite: Evaluation required)" }
  ]

  labelsToFilter = [
    "Leander Beginner Kids",
    "Leander Intermediate Kids (Pre-requisite: Evaluation required)",
    "Leander Advanced Team",
    "Leander Competition Team (2 Classes in Leander and 3 Classes in Roundrock)",
    "Leander Beginner Adult",
    "Leander Intermediate Adult (Pre-requisite: Evaluation required)"
  ];



  constructor(private common: CommonService, private router: Router) {
    this.getWebsiteSetting();
  }

  ngOnInit() {
    this.getSchedule();
    this.getStudentLabel();
    this.getcoaches()

  }

  getWebsiteSetting() {

    this.common.serverdata('getWebsiteSetting?page=training&section=schedule', []).subscribe(
      res => {
        this.data = res;
        console.log(this.data)
        this.schedule = this.data.response[0].dataset[0].value
      },
      errors => {
        console.log(errors);
      }
    )
  }
  // getSchedule() {
  //   this.common.serverdata('getClassTiming', []).subscribe(
  //     res => {
  //       this.scheduleclass = res;
  //       console.log(this.scheduleclass);
  //       this.schedules = this.scheduleclass.response;
  //       console.log(this.schedules);

  //     },
  //     err => {
  //       console.log(err)
  //     }
  //   );
  // }


  getSchedule() {
    this.common.serverdata('getClassTiming', []).subscribe(
      res => {
        this.scheduleclass = res;
        console.log(this.scheduleclass);

        // filter
        let filteredData = this.scheduleclass.response.filter(item =>
          this.labelsToFilter.includes(item.label.name)
        );
        console.log(filteredData);

        this.schedules = filteredData;

        // this.schedules = this.splitArrayIntoChunksOfLen(filteredData, 2);
        console.log(this.schedules);

      },
      err => {
        console.log(err)
      }
    );
  }

  convertTime(time) {
    let a = moment(time, ["HH:mm"]).format('h:mm a');
    return a;
  }

  splitArrayIntoChunksOfLen(arr, len) {
    var chunks = [], i = 0, n = arr.length;
    while (i < n) {
      chunks.push(arr.slice(i, i += len));
    }
    return chunks;
  }

  getStudentLabel() {
    this.common.serverdata('getStudentLevel', []).subscribe(
      res => {
        this.data = res;
        this.studentLabels = this.data.response;
        console.log(this.studentLabels);
      },
      err => {
        console.log(err)
      }
    );
  }

  getcoaches() {
    this.common.serverdata('getCoaches', []).subscribe(
      res => {
        this.data = res;
        this.allcoaches = this.data.result;
        console.log(this.allcoaches);

      },
      err => {
        console.log(err)
      }
    )
  }

  coachenquiry() {
    this.servicedata = {
      name: this.name,
      email: this.email,
      residence: this.residence,
      summary: this.summary,
      certificate: this.certificate,
      history: this.history,
      reference: this.reference,
      mobile: this.mobile_no
    }
    this.common.serverdatapost('addCoachInquiry', this.servicedata).subscribe(
      res => {
        this.data = res;
        console.log(this.data);
        if (this.data.status) {
          this.successMessage = this.data.message;
          setTimeout(() => {
            document.getElementById('successModal').style.display = "block";
          }, 500);
          setTimeout(() => {
            document.getElementById('successModal').style.display = "none";
            location.reload()
          }, 3000);

        }
      },
      err => {
        console.log(err)
      }
    );
  }
  scrollToSection(sectionId: string): void {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

}
