import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { HomeComponent } from "./home/home.component";
import { FooterComponent } from "./footer/footer.component";
import { TrainingComponent } from "./training/training.component";
import { HeaderComponent } from "./header/header.component";
import { ContactComponent } from "./contact/contact.component";
import { AboutComponent } from "./about/about.component";
import { ChessclassComponent } from "./chessclass/chessclass.component";
import { EventComponent } from "./event/event.component";
import { UpcomingComponent } from "./upcoming/upcoming.component";
import { UpcomingLocationComponent } from './upcoming-location/upcoming-location.component';
import { MembershipComponent } from "./membership/membership.component";
import { FbaRulesComponent } from "./fba-rules/fba-rules.component";
import { LoginComponent } from "./login/login.component";
import { ProfileComponent } from "./profile/profile.component";
import { DashboardComponent } from './dashboard/dashboard.component';
import { TrainingclassComponent } from './trainingclass/trainingclass.component';
import { ReservecourtComponent } from './reservecourt/reservecourt.component';
import { GroupComponent } from './group/group.component';
import { AuthGuard } from './guard/auth.guard';
import { MembershipContractComponent } from './membership-contract/membership-contract.component';
import{BlogsComponent} from './blogs/blogs.component';
import{ManageBlogComponent} from './manage-blog/manage-blog.component';
import { BlogdetailComponent } from "./blogdetail/blogdetail.component";
import { CreateBlogComponent } from "./create-blog/create-blog.component";
import { AchievementComponent } from "./achievement/achievement.component";
import { CovidGuidelineComponent } from "./covid-guideline/covid-guideline.component";
import { MambershipRoundRockComponent } from "./mambership-round-rock/mambership-round-rock.component";
import {  LeanderComponent } from "./leander/leander.component";
import {  RoundRockTraniningComponent } from "./round-rock-tranining/round-rock-tranining.component";
import {  LeanderTrainingComponent } from "./leander-training/leander-training.component";





const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "home", component: HomeComponent },
  { path: "footer", component: FooterComponent },
  { path: "header", component: HeaderComponent },
  { path: "contact-us", component: ContactComponent },
  { path: "training-video", component: AboutComponent },
  { path: "chess-class", component: ChessclassComponent },
  { path: "event", component: EventComponent },
  { path: "upcoming-camps", component: UpcomingComponent },
  { path: "upcoming-location", component: UpcomingLocationComponent },
  { path: "training-program-and-coaches", component: TrainingComponent },
  { path: "membership", component: MembershipComponent },
  { path: "membership-contract", component: MembershipContractComponent },
  { path: "our-rules", component: FbaRulesComponent },
  { path: "login", component: LoginComponent },
  { path: "dashboard", component: DashboardComponent,canActivate:[AuthGuard] },
  { path: "profile", component: ProfileComponent,canActivate:[AuthGuard] },
  { path: "enroll-class", component: TrainingclassComponent},
  { path: "reserve-court", component: ReservecourtComponent,canActivate:[AuthGuard] },
  { path: "group", component: GroupComponent,canActivate:[AuthGuard] },
  {path:"blogs",component: BlogsComponent},
  {path:"manage-blog",component: ManageBlogComponent},
  {path:"blogdetail/:id",component:BlogdetailComponent},
  {path:"create-blog",component:CreateBlogComponent},
  {path:"achievements",component:AchievementComponent},
  {path:"covid-guideline",component:CovidGuidelineComponent},
  {path:"membership-round-rock",component: MambershipRoundRockComponent},
  {path:"membership-leander",component: LeanderComponent },
  {path:"round-rock-training",component:RoundRockTraniningComponent},
  {path:"leander-training",component:LeanderTrainingComponent}



];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
