import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { FooterComponent } from "./footer/footer.component";
import { TrainingComponent } from "./training/training.component";
import { HeaderComponent } from './header/header.component';
import { AboutComponent } from './about/about.component';
import { ChessclassComponent } from './chessclass/chessclass.component';
import { ContactComponent } from './contact/contact.component';
import { EventComponent } from './event/event.component';
import { FbaRulesComponent } from './fba-rules/fba-rules.component';
import { MembershipComponent } from './membership/membership.component';
import { UpcomingComponent } from './upcoming/upcoming.component';
import { UpcomingLocationComponent } from './upcoming-location/upcoming-location.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { TrainingclassComponent } from './trainingclass/trainingclass.component';
import { ReservecourtComponent } from './reservecourt/reservecourt.component';
import { GroupComponent } from './group/group.component';

import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { MembershipContractComponent } from './membership-contract/membership-contract.component';
import { BlogsComponent } from './blogs/blogs.component';
import { ManageBlogComponent } from './manage-blog/manage-blog.component';

import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { BlogdetailComponent } from './blogdetail/blogdetail.component';
import { CreateBlogComponent } from './create-blog/create-blog.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SignaturePadModule } from 'angular2-signaturepad';
import { AchievementComponent } from './achievement/achievement.component';
import { CovidGuidelineComponent } from './covid-guideline/covid-guideline.component';

import { TruncatePipe } from './truncate.pipe';
import { CarouselComponent } from "./carousel/carousel.component";
import { LatestNewsComponent } from "./latest-news/latest-news.component";
import { MambershipRoundRockComponent } from './mambership-round-rock/mambership-round-rock.component';
import { LeanderComponent } from './leander/leander.component';
import { RoundRockTraniningComponent } from './round-rock-tranining/round-rock-tranining.component';
import { LeanderTrainingComponent } from './leander-training/leander-training.component';




@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    CarouselComponent,
    LatestNewsComponent,
    TrainingComponent,
    HeaderComponent,
    AboutComponent,
    ChessclassComponent,
    ContactComponent,
    EventComponent,
    FbaRulesComponent,
    MembershipComponent,
    UpcomingComponent,
    UpcomingLocationComponent,
    LoginComponent,
    ProfileComponent,
    DashboardComponent,
    SidemenuComponent,
    TrainingclassComponent,
    ReservecourtComponent,
    GroupComponent,
    MembershipContractComponent,
    BlogsComponent,
    ManageBlogComponent,
    BlogdetailComponent,
    CreateBlogComponent,
    AchievementComponent,
    CovidGuidelineComponent,
    TruncatePipe,
    MambershipRoundRockComponent,
    LeanderComponent,
    RoundRockTraniningComponent,
    LeanderTrainingComponent,
    
   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    RoundProgressModule,
    CKEditorModule,
    Ng4LoadingSpinnerModule.forRoot(),
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    SignaturePadModule
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }